.Board {
  margin: 0 auto;
  border-radius: 10px;
  /* box-shadow: 10px 10px 0 red; */
  /* box-shadow: 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff; */
}

table tr:first-child td:first-child {
  border-radius: 10px 0 0 0 !important;
}
table tr:first-child td:last-child {
  border-radius: 0 10px 0 0 !important;
}
table tr:last-child td:first-child {
  border-radius: 0 0 0 10px !important;
}
table tr:last-child td:last-child {
  border-radius: 0 0 10px 0 !important;
}

@font-face {
  font-family: 'Lato', sans-serif;

  src: url(https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Neonderthaw&display=swap);
}

/* https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf */
.Board-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.neon-orange {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 40px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}


.neon-orange-small {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}

.neon-blue {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 30px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-blue-small {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-green {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 40px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.neon-green-small {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.winner .neon-blue,
.winner .neon-orange {
  font-size: 100px;
}

@keyframes neon-orange {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@keyframes neon-blue {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@keyframes neon-green {
  0%,
  100% {
    text-shadow: 0 0 1vw #06f928, 0 0 3vw #06f928, 0 0 10vw #06f928,
      0 0 10vw #06f928, 0 0 0.4vw #d8ffb2, 0.5vw 0.5vw 0.1vw #17fd1c;
    color: #3fff0c;
  }
  50% {
    text-shadow: 0 0 0.5vw #357a23, 0 0 1.5vw #357a23, 0 0 5vw #357a23,
      0 0 5vw #357a23, 0 0 0.2vw #357a23, 0.5vw 0.5vw 0.1vw #27521c;
    color: #88de71;
  }
}
