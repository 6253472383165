@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Staatliches&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    background: url(/static/media/fortblox.e8e09b5f.png) no-repeat center center fixed;


    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #071C2D;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}

.loadingbackground {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(./BloxEscape/assets/fortblox.png) no-repeat center center fixed; */


    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #000000;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */




.customFont{
  font-family: 'Staatliches', cursive;
}

.spaceTopHome{
  margin-top: 100px;
}

.center{
  align-items: center;
}
.aligned{
  display: flex;
  margin: 40px auto;
  justify-content: center;
  align-items: center;

}


.customBodyMiniGame {
  background-color: #001219;
  border-width: 0px;
  color: #172774;
  border-radius: 0px 0px 10px 10px;
  text-align: center;

}

.cardRoundedMiniGame{
  border-radius: 15px 50px 0px 0px;
  color: #172774;


}

.MiniGameTitle{
  font-size: 1.4rem;
  color: #ee9b00;
  text-align: center;

}

.MiniGameSubTitle{
  font-size: 1.2rem;
  color: #ee9b00;
  text-align: center;

}

.MiniGameDetails{
  font-size: 1rem;
  color: #ffffff;
  text-align: center;

}

.modal.Networkcustom .modal-content {
  position: fixed;
  width: 400px;
  z-index: 1040;
  top:10%;
  border: 1px solid #e5e5e5;
  background-color: white;

}

.customMobileButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  /* height: 35px; */
  width: 150px;
  font-size: 1.2rem;
}


.customMobileButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}

.landingPageFont{
  color:white;
}

.loadingDataFont{
  color:white;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height:80vh;
}
.caption{
  display: block;
}

.list-group-mine .list-group-item {
  background-color: #16b330;
}

.list-group-not-complete .list-group-item {
  background-color: #7a7979;
}

.MyBakground{
  background: #001219;
  font-size: 1.1rem;

}

.customLinks{
    color: rgba(255,255,255,.5);
    padding-right: .5rem;
    padding-left: .5rem;
    display: block;
    padding: .5rem .5rem;
    text-decoration: none;
    background-color: transparent;
}
.customLinks:hover {
  color: rgba(255,255,255,.8);
  text-decoration: none;

}

.customWalletButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1rem;
}


.customWalletButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 100px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;
}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}

.largeEnter{
  font-size: 2.3rem;
  color: #ee9b00;
  float: center;

}

.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 100px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}

.customButtonBlog{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1rem;
}

.customButtonBlog:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}

.blogTitle{
  font-size: 1.4rem;
}

.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.homeSubTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  text-align: center;
}

.card-footer{
  background-color: white;
}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 100px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.noteTitle{
  color: #ee9b00;
  float: center;
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}


.homeSubTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  text-align: center;
}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 100px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;


}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.3rem;
  color: #ffffff;
  border-color: #ee9b00;
  border-width: 3px;

}

.customGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}

.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}
.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.customRoundedTable{
  border-radius: 15px 0px 10px 10px;
  border:0;
  background-color: #001219;
  align-items: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
  align-items: center;

}

.sticky-column {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: #001219;
}

.containerTable {
    max-height: 290px;
    overflow-y: scroll;
    width: 100%;

    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 50px;
    background-color: #ee9b00;
    background: #001219;
    scroll-behavior: smooth;

}


a, a:hover {
    color: white;
}


.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 100px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.noteTitle{
  color: #ee9b00;
  float: center;
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}


.homeSubTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  text-align: center;
}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.customRoundedTable{
  border-radius: 15px 0px 10px 10px;
  border:0;
  background-color: #001219;
  align-items: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
  align-items: center;

}

.sticky-column {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: #001219;
}

.containerTable {
    max-height: 290px;
    overflow-y: scroll;
    width: 100%;

    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 50px;
    background-color: #ee9b00;
    background: #001219;
    scroll-behavior: smooth;

}





.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.custombackground {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    background: url(/static/media/treasureblox_jetswap_partnership_background.27b9e6d4.png) no-repeat center center fixed;
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */


.custombackgroundmeter {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    background: url(/static/media/treasureblox_meter_partnership_background.74e5550e.png) no-repeat center center fixed;
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */



.customFont{
  font-family: 'Staatliches', cursive;
}

.spaceTopHome{
  margin-top: 100px;
}

.center{
  align-items: center;
}
.aligned{
  display: flex;
  margin: 40px auto;
  justify-content: center;
  align-items: center;

}


.customBodyMiniGame {
  background-color: #001219;
  border-width: 0px;
  color: #172774;
  border-radius: 0px 0px 10px 10px;
  text-align: center;

}

.customEnterTeam {
  background-color: #001219;
  border-width: 0px;
  color: #172774;
  border-radius: 0px 0px 10px 10px;
  text-align: center;

}

.cardRoundedMiniGame{
  border-radius: 15px 50px 0px 0px;
  color: #172774;
}


.cardRoundedEnterPage{
  border-radius: 25px 25px 25px 25px;
  color: #172774;
  height: 50px;
  width: 50px;
  object-fit: cover;

}

.cardRoundedEnterPageMini{
  border-radius: 10px 10px 10px 10px;
  color: #172774;
  height: 100%;
  width: 100px;
  object-fit: cover;

}

.cardRoundedEnterPageLarge{
  /* border-radius: 100px 100px 100px 100px; */
  color: #172774;
  height: 250px;
  width: 250px;
  object-fit: cover;

}

.MiniGameTitle{
  font-size: 1.4rem;
  color: #ee9b00;
  text-align: center;

}
.MiniGameSmallTitle{
  font-size: 1rem;
  color: #000000;
  text-align: center;

}

.MiniGameEnter{
  font-size: 1.2rem;
  color: #000000;
  text-align: center;
}

.MiniGameEnterTab{
  font-size: 1.4rem;
  text-align: center;
}

.MiniGameTargetEnter{
  font-size: 2rem;
  color: #000000;
  pointer-events: none;


}

.MiniGameTitleEnter{
  font-size: 1rem;
  color: #ee9b00;
  text-align: center;
}

.MiniGameTitleEnterTargetRaised{
  font-size: 1.4rem;
  text-align: left;
  color: #ee9b00;
  pointer-events: none;
}

.MiniGamePrizeEnter{
  font-size: 2.4rem;
  color: #ee9b00;
  text-align: left;

}

.MegaGamePrize{
  font-size: 4rem;
  color: #000000;

}

.StatsGameEnter{
  font-size: 3.4rem;
  color: #ee9b00;
  text-align: center;

}

.StatsGameEnterSub{
  font-size: 2rem;
  color: #ee9b00;
  text-align: center;

}

.StatsGameEnterSmallSub{
  font-size: 1rem;
  color: #ee9b00;
  text-align: center;

}

.MiniGameSubTitle{
  font-size: 1.2rem;
  color: #ee9b00;
  text-align: center;

}

.MiniGameDetails{
  font-size: 1rem;
  color: #ffffff;
  text-align: center;

}


.customButtonEntering{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1rem;
}

.customButtonEntering:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}

.MiniGameTitleEnterMore{
  font-size: 1.2rem;
  text-align: center;
  color: #000000;
}

.blink_me {
  -webkit-animation: blinker 2s linear infinite;
          animation: blinker 2s linear infinite;
  color:#ee9b00;
}

.blink_me2 {
  -webkit-animation: blinker 1.5s linear infinite;
          animation: blinker 1.5s linear infinite;
  color:#ee9b00;
}

.blink_me3 {
  -webkit-animation: blinker 3s linear infinite;
          animation: blinker 3s linear infinite;
  color:#ffffff;
}

@-webkit-keyframes blinker {
  70% {
    opacity: .3;
  }
}

@keyframes blinker {
  70% {
    opacity: .3;
  }
}

.SubTitleHeader{
    font-size: 1rem;
    color: #ee9b00;
    text-align: center;
  }

  .footercard{
    font-size: 1.2rem;
    color: #ffffff;
    text-align: center;
  }

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 100px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;
}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


.customEnterButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 250px;
  font-size: 1rem;
}

.customEnterButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}

.largeEnter{
  font-size: 2.3rem;
  color: #ee9b00;
  float: center;

}

.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;


}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.3rem;
  color: #ffffff;
  border-color: #ee9b00;
  border-width: 3px;

}

.customGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}

.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}
.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.customRoundedTable{
  border-radius: 15px 0px 10px 10px;
  border:0;
  background-color: #001219;
  align-items: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
  align-items: center;

}

.sticky-column {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: #001219;
}

.containerTable {
    max-height: 290px;
    overflow-y: scroll;
    width: 100%;

    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 50px;
    background-color: #ee9b00;
    background: #001219;
    scroll-behavior: smooth;

}


a, a:hover {
    color: white;
}


.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #ee9b00;
}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 100px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.noteTitle{
  color: #ee9b00;
  float: center;
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}


.homeSubTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  text-align: center;
}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.customRoundedTable{
  border-radius: 15px 0px 10px 10px;
  border:0;
  background-color: #001219;
  align-items: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
  align-items: center;

}

.sticky-column {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: #001219;
}

.containerTable {
    max-height: 290px;
    overflow-y: scroll;
    width: 100%;

    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 50px;
    background-color: #ee9b00;
    background: #001219;
    scroll-behavior: smooth;

}





.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

{
  box-sizing:border-box;
}
body{
  background-color: #e6e6e6;
  color: black;
  display:flex;
  flex-direction: column;
  min-height: 100vw;
  justify-content: center;
  align-items: center;
  margin: 0;


}
#canvas {
  background-color: #ffffff;
}

.wording{
    font-size: 1.8rem;
    color: #ffffff;
    text-align: center;

}

.meter_wording{
    font-size: 1.8rem;
    color: #000000;
    text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 100px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;
}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}
.customButtonApprove{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}



@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}



@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}

.largeEnter{
  font-size: 2.3rem;
  color: #ee9b00;
  float: center;

}

.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;


}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.3rem;
  color: #ffffff;
  border-color: #ee9b00;
  border-width: 3px;

}

.customGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}

.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.customRoundedTable{
  border-radius: 15px 0px 10px 10px;
  border:0;
  background-color: #001219;
  align-items: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
  align-items: center;

}

.sticky-column {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: #001219;
}

.containerTable {
    max-height: 290px;
    overflow-y: scroll;
    width: 100%;

    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 50px;
    background-color: #ee9b00;
    background: #001219;
    scroll-behavior: smooth;

}





.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 100px;
}

.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1.2rem;
}


.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.gameTitle{
  font-size: 1.8rem;
  color: #9b2226;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.noteTitle{
  color: #ee9b00;
  float: center;
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}


.homeSubTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  text-align: center;
}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.customRoundedTable{
  border-radius: 15px 0px 10px 10px;
  border:0;
  background-color: #001219;
  align-items: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
  align-items: center;

}

.sticky-column {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: #001219;
}

.containerTable {
    max-height: 290px;
    overflow-y: scroll;
    width: 100%;

    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 50px;
    background-color: #ee9b00;
    background: #001219;
    scroll-behavior: smooth;

}


a, a:hover {
    color: white;
}


.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    /* background: url(../GamesSelection/FighterPilot/assets/Cockpit_Random4.png) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}

@font-face {
  font-family: 'Lato', sans-serif;

  src: url(https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Neonderthaw&display=swap);
}

/* https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf */
.Board-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.neon-orange {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 50px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}


.neon-orange-small {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}

.neon-blue {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 50px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-blue-small {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-green {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 50px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.neon-green-small {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.winner .neon-blue,
.winner .neon-orange {
  font-size: 100px;
}

@-webkit-keyframes neon-orange {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@keyframes neon-orange {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@-webkit-keyframes neon-blue {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@keyframes neon-blue {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@-webkit-keyframes neon-green {
  0%,
  100% {
    text-shadow: 0 0 1vw #06f928, 0 0 3vw #06f928, 0 0 10vw #06f928,
      0 0 10vw #06f928, 0 0 0.4vw #d8ffb2, 0.5vw 0.5vw 0.1vw #17fd1c;
    color: #3fff0c;
  }
  50% {
    text-shadow: 0 0 0.5vw #357a23, 0 0 1.5vw #357a23, 0 0 5vw #357a23,
      0 0 5vw #357a23, 0 0 0.2vw #357a23, 0.5vw 0.5vw 0.1vw #27521c;
    color: #88de71;
  }
}

@keyframes neon-green {
  0%,
  100% {
    text-shadow: 0 0 1vw #06f928, 0 0 3vw #06f928, 0 0 10vw #06f928,
      0 0 10vw #06f928, 0 0 0.4vw #d8ffb2, 0.5vw 0.5vw 0.1vw #17fd1c;
    color: #3fff0c;
  }
  50% {
    text-shadow: 0 0 0.5vw #357a23, 0 0 1.5vw #357a23, 0 0 5vw #357a23,
      0 0 5vw #357a23, 0 0 0.2vw #357a23, 0.5vw 0.5vw 0.1vw #27521c;
    color: #88de71;
  }
}


.instructions{
  color: #ffffff;
  font-size: 22px;
}

.background {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(./treasureblox_background.jpg) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #071C2D;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */



.customFont{
  font-family: 'Staatliches', cursive;
}

.spaceTopHome{
  margin-top: 100px;
}

.center{
  align-items: center;
}
.aligned{
  display: flex;
  margin: 40px auto;
  justify-content: center;
  align-items: center;

}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;

}
.modal-content {
  background: transparent;

}

.gameOneMobile{
  font-size: 1.4rem;
  color: #ffffff;
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
}



.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}
.customButtonDecide{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 100%;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.1rem;
  color: #001219;
}

.gameFont{
  font-size: 1rem;
  color: white;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.siteTitleDesc{
  font-size: 1.4rem;
  color: #ffffff;
  text-align: center;

}

.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;


}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    /* background: url(../GamesSelection/FighterPilot/assets/Cockpit_Random4.png) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    /* background: url(../GamesSelection/FighterPilot/assets/Cockpit_Random4.png) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}

@font-face {
  font-family: 'Lato', sans-serif;

  src: url(https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Neonderthaw&display=swap);
}

/* https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf */
.Board-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.neon-orange {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 50px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}


.neon-orange-small {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}

.neon-blue {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 50px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-blue-small {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-green {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 50px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.neon-green-small {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.winner .neon-blue,
.winner .neon-orange {
  font-size: 100px;
}

@-webkit-keyframes neon-orange {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@keyframes neon-orange {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@-webkit-keyframes neon-blue {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@keyframes neon-blue {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@-webkit-keyframes neon-green {
  0%,
  100% {
    text-shadow: 0 0 1vw #06f928, 0 0 3vw #06f928, 0 0 10vw #06f928,
      0 0 10vw #06f928, 0 0 0.4vw #d8ffb2, 0.5vw 0.5vw 0.1vw #17fd1c;
    color: #3fff0c;
  }
  50% {
    text-shadow: 0 0 0.5vw #357a23, 0 0 1.5vw #357a23, 0 0 5vw #357a23,
      0 0 5vw #357a23, 0 0 0.2vw #357a23, 0.5vw 0.5vw 0.1vw #27521c;
    color: #88de71;
  }
}

@keyframes neon-green {
  0%,
  100% {
    text-shadow: 0 0 1vw #06f928, 0 0 3vw #06f928, 0 0 10vw #06f928,
      0 0 10vw #06f928, 0 0 0.4vw #d8ffb2, 0.5vw 0.5vw 0.1vw #17fd1c;
    color: #3fff0c;
  }
  50% {
    text-shadow: 0 0 0.5vw #357a23, 0 0 1.5vw #357a23, 0 0 5vw #357a23,
      0 0 5vw #357a23, 0 0 0.2vw #357a23, 0.5vw 0.5vw 0.1vw #27521c;
    color: #88de71;
  }
}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.background {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    /* background: url(../GamesSelection/FighterPilot/assets/Cockpit_Random4.png) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}



.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.background {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(./treasureblox_background.jpg) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #071C2D;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */



.customFont{
  font-family: 'Staatliches', cursive;
}

.spaceTopHome{
  margin-top: 100px;
}

.center{
  align-items: center;
}
.aligned{
  display: flex;
  margin: 40px auto;
  justify-content: center;
  align-items: center;

}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;

}
.modal-content {
  background: transparent;
  border: 0;


}



.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}
.customButtonDecide{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 100%;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.siteTitleDesc{
  font-size: 1.4rem;
  color: #ffffff;
  text-align: center;

}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    /* background: url(../GamesSelection/FighterPilot/assets/Cockpit_Random4.png) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    /* background: url(../GamesSelection/FighterPilot/assets/Cockpit_Random4.png) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.background {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    /* background: url(../GamesSelection/FighterPilot/assets/Cockpit_Random4.png) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}

.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    background: url(/static/media/Cockpit_Random4.c0fa114a.png) no-repeat center center fixed;

    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}


/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */



.customFont{
  font-family: 'Staatliches', cursive;
}

.spaceTopHome{
  margin-top: 100px;
}

.center{
  align-items: center;
}
.aligned{
  display: flex;
  margin: 40px auto;
  justify-content: center;
  align-items: center;

}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;

}
.modal-content {
  background: transparent;
  border: 0;


}

.wording{
    font-size: 1.8rem;
    color: #000000;
    text-align: center;

}

.meter_wording{
    font-size: 1.8rem;
    color: #000000;
    text-align: center;

}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    background: url(/static/media/Cockpit_Random4.c0fa114a.png) no-repeat center center fixed;
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;


}

.outerKeyPad{
  border-radius: 10px 10px 10px 10px;

  background-color: #000000;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 100%;
  width: 25%;
}

.meterOuterKeyPad{
  border-radius: 10px 10px 10px 10px;

  background-color: #424543;
  border-color: #0ebd1f;
  outline-color: #0ebd1f;
  color: #001219;
  height: 100%;
  width: 25%;
}

.topKeyPad{
  border-radius: 10px 10px 10px 10px;

  background-color: #212529;
  color: #001219;
  height: 100%;
  width: 90%;

}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */

.background {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(./treasureblox_background.jpg) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #071C2D;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}





.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;


}

.Cell {
  height: 50px;
  width: 50px;
  border-radius: 1px;
  background-color: #263238;
  transition: background-color 0.7s ease;
}

.Cell-lit {
  background-color: #ee9b00;
}

.Board {
  margin: 0 auto;
  border-radius: 10px;
  /* box-shadow: 10px 10px 0 red; */
  /* box-shadow: 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff; */
}

table tr:first-child td:first-child {
  border-radius: 10px 0 0 0 !important;
}
table tr:first-child td:last-child {
  border-radius: 0 10px 0 0 !important;
}
table tr:last-child td:first-child {
  border-radius: 0 0 0 10px !important;
}
table tr:last-child td:last-child {
  border-radius: 0 0 10px 0 !important;
}

@font-face {
  font-family: 'Lato', sans-serif;

  src: url(https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Neonderthaw&display=swap);
}

/* https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf */
.Board-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.neon-orange {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 40px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}


.neon-orange-small {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}

.neon-blue {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 30px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-blue-small {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-green {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 40px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.neon-green-small {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.winner .neon-blue,
.winner .neon-orange {
  font-size: 100px;
}

@-webkit-keyframes neon-orange {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@keyframes neon-orange {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@-webkit-keyframes neon-blue {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@keyframes neon-blue {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@-webkit-keyframes neon-green {
  0%,
  100% {
    text-shadow: 0 0 1vw #06f928, 0 0 3vw #06f928, 0 0 10vw #06f928,
      0 0 10vw #06f928, 0 0 0.4vw #d8ffb2, 0.5vw 0.5vw 0.1vw #17fd1c;
    color: #3fff0c;
  }
  50% {
    text-shadow: 0 0 0.5vw #357a23, 0 0 1.5vw #357a23, 0 0 5vw #357a23,
      0 0 5vw #357a23, 0 0 0.2vw #357a23, 0.5vw 0.5vw 0.1vw #27521c;
    color: #88de71;
  }
}

@keyframes neon-green {
  0%,
  100% {
    text-shadow: 0 0 1vw #06f928, 0 0 3vw #06f928, 0 0 10vw #06f928,
      0 0 10vw #06f928, 0 0 0.4vw #d8ffb2, 0.5vw 0.5vw 0.1vw #17fd1c;
    color: #3fff0c;
  }
  50% {
    text-shadow: 0 0 0.5vw #357a23, 0 0 1.5vw #357a23, 0 0 5vw #357a23,
      0 0 5vw #357a23, 0 0 0.2vw #357a23, 0.5vw 0.5vw 0.1vw #27521c;
    color: #88de71;
  }
}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    background: url(/static/media/Cockpit_Random4.c0fa114a.png) no-repeat center center fixed;
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    background: url(/static/media/Cockpit_Random4.c0fa114a.png) no-repeat center center fixed;
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}


@font-face {
  font-family: 'Lato', sans-serif;

  src: url(https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Neonderthaw&display=swap);
}

/* https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf */
.Board-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.neon-orange {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 40px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}


.neon-orange-small {
  font-family: 'Lato', sans-serif;


  color: #fb4264;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #f40a35; */
  animation: neon-orange 1s ease infinite;
  -moz-animation: neon-orange 1s ease infinite;
  -webkit-animation: neon-orange 1s ease infinite;
}

.neon-blue {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 40px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-blue-small {
  font-family: 'Lato', sans-serif;

  color: #72baef;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-blue 2s linear infinite;
  -moz-animation: neon-blue 2s linear infinite;
  -webkit-animation: neon-blue 2s linear infinite;
  -o-animation: neon-blue 2s linear infinite;
}

.neon-green {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 40px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.neon-green-small {
  font-family: 'Lato', sans-serif;

  color: #06f928;
  font-size: 20px;
  font-weight: 100;

  /* text-shadow: 0 0 3vw #2356ff; */
  animation: neon-green 2s linear infinite;
  -moz-animation: neon-green 2s linear infinite;
  -webkit-animation: neon-green 2s linear infinite;
  -o-animation: neon-green 2s linear infinite;
}

.winner .neon-blue,
.winner .neon-orange {
  font-size: 100px;
}

@-webkit-keyframes neon-orange {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@keyframes neon-orange {
  0%,
  100% {
    text-shadow: 0 0 1vw #fa1c16, 0 0 3vw #fa1c16, 0 0 10vw #fa1c16,
      0 0 10vw #fa1c16, 0 0 0.4vw #fed128, 0.5vw 0.5vw 0.1vw #806914;
    color: #fed128;
  }
  50% {
    text-shadow: 0 0 0.5vw #800e0b, 0 0 1.5vw #800e0b, 0 0 5vw #800e0b,
      0 0 5vw #800e0b, 0 0 0.2vw #800e0b, 0.5vw 0.5vw 0.1vw #40340a;
    color: #806914;
  }
}

@-webkit-keyframes neon-blue {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@keyframes neon-blue {
  0%,
  100% {
    text-shadow: 0 0 1vw #1041ff, 0 0 3vw #1041ff, 0 0 10vw #1041ff,
      0 0 10vw #1041ff, 0 0 0.4vw #8bfdfe, 0.5vw 0.5vw 0.1vw #147280;
    color: #28d7fe;
  }
  50% {
    text-shadow: 0 0 0.5vw #082180, 0 0 1.5vw #082180, 0 0 5vw #082180,
      0 0 5vw #082180, 0 0 0.2vw #082180, 0.5vw 0.5vw 0.1vw #0a3940;
    color: #146c80;
  }
}

@-webkit-keyframes neon-green {
  0%,
  100% {
    text-shadow: 0 0 1vw #06f928, 0 0 3vw #06f928, 0 0 10vw #06f928,
      0 0 10vw #06f928, 0 0 0.4vw #d8ffb2, 0.5vw 0.5vw 0.1vw #17fd1c;
    color: #3fff0c;
  }
  50% {
    text-shadow: 0 0 0.5vw #357a23, 0 0 1.5vw #357a23, 0 0 5vw #357a23,
      0 0 5vw #357a23, 0 0 0.2vw #357a23, 0.5vw 0.5vw 0.1vw #27521c;
    color: #88de71;
  }
}

@keyframes neon-green {
  0%,
  100% {
    text-shadow: 0 0 1vw #06f928, 0 0 3vw #06f928, 0 0 10vw #06f928,
      0 0 10vw #06f928, 0 0 0.4vw #d8ffb2, 0.5vw 0.5vw 0.1vw #17fd1c;
    color: #3fff0c;
  }
  50% {
    text-shadow: 0 0 0.5vw #357a23, 0 0 1.5vw #357a23, 0 0 5vw #357a23,
      0 0 5vw #357a23, 0 0 0.2vw #357a23, 0.5vw 0.5vw 0.1vw #27521c;
    color: #88de71;
  }
}

/* @keyframes blink {
    0% {
        opacity: 1;
    }
    60% {
        opacity: .7;
    }
    100% {
        opacity: 1;
    }
} */



.background {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    /* background: url(../GamesSelection/FighterPilot/assets/Cockpit_Random4.png) no-repeat center center fixed; */
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}




.customRounded{
  border-radius: 15px 50px 0px 0px;
  border:0;

}

.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;

}

.spaceTop{
  margin-top: 70px;
}



.customButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 60px;
  width: 200px;
  font-size: 1.4rem;
}

.customButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  -webkit-animation: wobble 1s 1;
          animation: wobble 1s 1;
}


@-webkit-keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


@keyframes wobble {
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.tinyCustomButtonDescription{
  background-color: #94d2bd;
  border-color: transparent;
  outline-color: #94d2bd;
  color: #001219;
  margin-right:5px;
  margin-left:5px;
}

.tinyCustomButtonDescription:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;

}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.prePrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gamePrize{
  font-size: 2.7rem;
  color: #ee9b00;
}
.gamePrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}

.gameTitleEnter{
  font-size: 2rem;
  color: #ffffff;
}
.gamePrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gamePrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postPrize{
  font-size: 1rem;
  color: #bb3e03;

}

.enterGameTitle{
  font-size: 1.6rem;
  color: #ffffff;
}
.gameText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}
.hash{
  font-size: .8rem;
  color: #005f73;

}

.customBodyThree {
  background-color: #001219;
  border-width: 0px;
  color: white;
  border-radius: 15px 50px 10px 10px;
  text-align: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;

}
.descriptionBody{
border-bottom: 1px solid rgba(255,255,255,.25);
}

.lineThrough{
  text-decoration: line-through
}


.siteTitle{
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;

}

.GameFont{
  font-size: 1.8rem;
  color: #000000;
  text-align: center;
}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;
    border: 0;

}
.modal-content {
  background: transparent;
  border: 0;

}




#canvas {
  background-color: "";
  /* opacity: 80%; */
}

