.customRoundedOne{
  border-radius: 15px 50px 10px 10px;
  border:0;
  background-color: #001219;

}

.customRoundedTable{
  border-radius: 15px 0px 10px 10px;
  border:0;
  background-color: #001219;
  align-items: center;

}

.descriptionTitle{
  font-size: 1.3rem;
  color: #ee9b00;
  float: center;
  align-items: center;

}

.sticky-column {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    background: #001219;
}

.containerTable {
    max-height: 290px;
    overflow-y: scroll;
    width: 100%;

    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 50px;
    background-color: #ee9b00;
    background: #001219;
    scroll-behavior: smooth;

}


a, a:hover {
    color: white;
}


.cardRounded{
  border-radius: 15px 50px 10px 10px;
  border:0;


}

.spaceTop{
  margin-top: 100px;

}


.customBlockquote{
  border-radius: 15px 50px 10px 10px;
  border:0;
}

.card .card-header {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 15px 50px 0px 0px;

}
.customBody {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 3px;
  color: white;
  border-radius: 0px 0px 10px 10px;


}

.customBodyTwo {
  background-color: #001219;
  border-color: #ee9b00;
  border-width: 0px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  text-align: center;


}


.tinyCustomButton{
  background-color: #94d2bd;
  border-color: #94d2bd;
  outline-color: #94d2bd;
  border-color: white;
  color: #001219;
}

.tinyCustomButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}

.customToolTip{
  background-color: #0a9396;
  border-color: #0a9396;
  outline-color: #0a9396;
  color: #001219;
  height: 40px;
  font-size: 1.3rem;
  float: right;
  margin-top: 10px;
}
.customToolTip:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
}
.preMainPrize{
  font-size: 1rem;
  color: #005f73;
  float: right;
}
.gameMainPrize{
  font-size: 3.4rem;
  color: #ee9b00;
}
.gameMainPrizeBnb{
  font-size: 2rem;
  color: #ee9b00;
}
.gameMainPrizeDark{
  font-size: 2.7rem;
  color: #0a9396;
}
.gameMainPrizeDarkBnb{
  font-size: 2rem;
  color: #0a9396;
}
.attemptMainPrize{
  font-size: 1.3rem;
  color: #bb3e03;
}
.postMainPrize{
  font-size: 1.2rem;
  color: #ffffff;
}

.gameMainTitle{
  font-size: 1.8rem;
  color: #ffffff;
}

.gameRiddle{
  font-size: 2.2rem;
  color: #001219;
}
.gameMainText{
  font-size: 1.3rem;
  color: #001219;
}

.readMe{
  color: #ca6702;
}

.readMeSmall{
  font-size: 1rem;
  color: #005f73;
}

.howToTitle{
  font-size: 1.8rem;
  color: #ee9b00;
  text-align: center;

}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #ee9b00;
}
