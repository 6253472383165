.custombackgroundfightergame {

    /* flashing  */
    /* animation: blink 2s;
    animation-iteration-count: infinite; */



    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(../assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed; */

    background: url(../GamesSelection/FighterPilot/assets/Cockpit_Random4.png) no-repeat center center fixed;

    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}


/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');



.customFont{
  font-family: 'Staatliches', cursive;
}

.spaceTopHome{
  margin-top: 100px;
}

.center{
  align-items: center;
}
.aligned{
  display: flex;
  margin: 40px auto;
  justify-content: center;
  align-items: center;

}

.modal.custom .modal-dialog {
    width:20%;
    position:fixed;
    bottom:0;
    left:0;
    margin:0;

}
.modal-content {
  background: transparent;
  border: 0;


}

.wording{
    font-size: 1.8rem;
    color: #000000;
    text-align: center;

}

.meter_wording{
    font-size: 1.8rem;
    color: #000000;
    text-align: center;

}
