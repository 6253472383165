{
  box-sizing:border-box;
}
body{
  background-color: #e6e6e6;
  color: black;
  display:flex;
  flex-direction: column;
  min-height: 100vw;
  justify-content: center;
  align-items: center;
  margin: 0;


}
#canvas {
  background-color: #ffffff;
}

.wording{
    font-size: 1.8rem;
    color: #ffffff;
    text-align: center;

}

.meter_wording{
    font-size: 1.8rem;
    color: #000000;
    text-align: center;

}
