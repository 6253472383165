.Cell {
  height: 50px;
  width: 50px;
  border-radius: 1px;
  background-color: #263238;
  transition: background-color 0.7s ease;
}

.Cell-lit {
  background-color: #ee9b00;
}
