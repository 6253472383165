.custombackground {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    background: url(./assets/images/treasureblox_jetswap_partnership_background.png) no-repeat center center fixed;
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');


.custombackgroundmeter {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    background: url(./assets/images/treasureblox_meter_partnership_background.png) no-repeat center center fixed;
    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #172774;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');



.customFont{
  font-family: 'Staatliches', cursive;
}

.spaceTopHome{
  margin-top: 100px;
}

.center{
  align-items: center;
}
.aligned{
  display: flex;
  margin: 40px auto;
  justify-content: center;
  align-items: center;

}


.customBodyMiniGame {
  background-color: #001219;
  border-width: 0px;
  color: #172774;
  border-radius: 0px 0px 10px 10px;
  text-align: center;

}

.customEnterTeam {
  background-color: #001219;
  border-width: 0px;
  color: #172774;
  border-radius: 0px 0px 10px 10px;
  text-align: center;

}

.cardRoundedMiniGame{
  border-radius: 15px 50px 0px 0px;
  color: #172774;
}


.cardRoundedEnterPage{
  border-radius: 25px 25px 25px 25px;
  color: #172774;
  height: 50px;
  width: 50px;
  object-fit: cover;

}

.cardRoundedEnterPageMini{
  border-radius: 10px 10px 10px 10px;
  color: #172774;
  height: 100%;
  width: 100px;
  object-fit: cover;

}

.cardRoundedEnterPageLarge{
  /* border-radius: 100px 100px 100px 100px; */
  color: #172774;
  height: 250px;
  width: 250px;
  object-fit: cover;

}

.MiniGameTitle{
  font-size: 1.4rem;
  color: #ee9b00;
  text-align: center;

}
.MiniGameSmallTitle{
  font-size: 1rem;
  color: #000000;
  text-align: center;

}

.MiniGameEnter{
  font-size: 1.2rem;
  color: #000000;
  text-align: center;
}

.MiniGameEnterTab{
  font-size: 1.4rem;
  text-align: center;
}

.MiniGameTargetEnter{
  font-size: 2rem;
  color: #000000;
  pointer-events: none;


}

.MiniGameTitleEnter{
  font-size: 1rem;
  color: #ee9b00;
  text-align: center;
}

.MiniGameTitleEnterTargetRaised{
  font-size: 1.4rem;
  text-align: left;
  color: #ee9b00;
  pointer-events: none;
}

.MiniGamePrizeEnter{
  font-size: 2.4rem;
  color: #ee9b00;
  text-align: left;

}

.MegaGamePrize{
  font-size: 4rem;
  color: #000000;

}

.StatsGameEnter{
  font-size: 3.4rem;
  color: #ee9b00;
  text-align: center;

}

.StatsGameEnterSub{
  font-size: 2rem;
  color: #ee9b00;
  text-align: center;

}

.StatsGameEnterSmallSub{
  font-size: 1rem;
  color: #ee9b00;
  text-align: center;

}

.MiniGameSubTitle{
  font-size: 1.2rem;
  color: #ee9b00;
  text-align: center;

}

.MiniGameDetails{
  font-size: 1rem;
  color: #ffffff;
  text-align: center;

}


.customButtonEntering{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  height: 40px;
  width: 150px;
  font-size: 1rem;
}

.customButtonEntering:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  animation: wobble 1s 1;
}

.MiniGameTitleEnterMore{
  font-size: 1.2rem;
  text-align: center;
  color: #000000;
}

.blink_me {
  animation: blinker 2s linear infinite;
  color:#ee9b00;
}

.blink_me2 {
  animation: blinker 1.5s linear infinite;
  color:#ee9b00;
}

.blink_me3 {
  animation: blinker 3s linear infinite;
  color:#ffffff;
}

@keyframes blinker {
  70% {
    opacity: .3;
  }
}

.SubTitleHeader{
    font-size: 1rem;
    color: #ee9b00;
    text-align: center;
  }

  .footercard{
    font-size: 1.2rem;
    color: #ffffff;
    text-align: center;
  }
