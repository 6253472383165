.background {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    background: url(./BloxEscape/assets/fortblox.png) no-repeat center center fixed;


    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #071C2D;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}

.loadingbackground {
    /* The image used */
    /* background-image: url(./treasure1.jpg); */
    /* background: url(./BloxEscape/assets/fortblox.png) no-repeat center center fixed; */


    /* background: url(./background2.jpg) no-repeat center center fixed; */
    /* background: url(./background1.png) no-repeat center center fixed; */

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #000000;

    /* background-attachment: fixed; */
    /* Full height */
    width: 100vw;
    height: 100%;
    min-height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');




.customFont{
  font-family: 'Staatliches', cursive;
}

.spaceTopHome{
  margin-top: 100px;
}

.center{
  align-items: center;
}
.aligned{
  display: flex;
  margin: 40px auto;
  justify-content: center;
  align-items: center;

}


.customBodyMiniGame {
  background-color: #001219;
  border-width: 0px;
  color: #172774;
  border-radius: 0px 0px 10px 10px;
  text-align: center;

}

.cardRoundedMiniGame{
  border-radius: 15px 50px 0px 0px;
  color: #172774;


}

.MiniGameTitle{
  font-size: 1.4rem;
  color: #ee9b00;
  text-align: center;

}

.MiniGameSubTitle{
  font-size: 1.2rem;
  color: #ee9b00;
  text-align: center;

}

.MiniGameDetails{
  font-size: 1rem;
  color: #ffffff;
  text-align: center;

}

.modal.Networkcustom .modal-content {
  position: fixed;
  width: 400px;
  z-index: 1040;
  top:10%;
  border: 1px solid #e5e5e5;
  background-color: white;

}

.customMobileButton{
  background-color: #ee9b00;
  border-color: #ee9b00;
  outline-color: #ee9b00;
  color: #001219;
  /* height: 35px; */
  width: 150px;
  font-size: 1.2rem;
}


.customMobileButton:hover{
  background-color: #005f73;
  border-color: #005f73;
  outline-color: #005f73;
  color: white;
  animation: wobble 1s 1;
}

.landingPageFont{
  color:white;
}

.loadingDataFont{
  color:white;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height:80vh;
}
.caption{
  display: block;
}

.list-group-mine .list-group-item {
  background-color: #16b330;
}

.list-group-not-complete .list-group-item {
  background-color: #7a7979;
}
